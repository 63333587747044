<template>
	<div id="fitness-wrap">
		<Header :navbg='true'></Header>
		<div class="section bg1">
			<div class="">
				<div class="title">创新型共享健身平台</div>
				<div class="subtitle">体验“多层次、多品牌、多区域、多体验”服务</div>
			</div>
			<div class="btn">了解更多</div>
		</div>
	   <div class="section bg2">
			<div class="title">健身一卡通</div>
			<div class="intr">
				“健身一卡通”是基于”小院生活”平台内的共享健身版块而衍生出的创新型健身会员平台,平台通过链接物业资源（酒店配套健身房，连锁品牌健身房、私教工作室等）、教练资源、消费者资源三方，针对平台会员实行一卡通用制,用户可享受健身平台合作内各种特色健身房相应服务，享受“多层次、多品牌、多区域、多体验”服务;
			</div>
		</div>
		<div class="van-swipe">
			<div class="title">经开未来城门店简介</div>
			<div class="intr">
				小院健身（经开未来城店）位于渭南市临渭区经开未来城小院生活图书馆二层，总占地面积500平米，区域包含有器械区、有氧区、动感单车房、瑜伽室、体测室、私教室、男女淋浴间等，设施设备齐全，能够为周边热爱健身的消费群体提供优质的健身场所体验。
			</div>
			<div class="subtitle">经开未来城店各功能区展示</div>
			<van-swipe :autoplay="3000" indicator-color="white" class="banner" @change="change">
				<van-swipe-item v-for="(item,index) in bannerList" :key="index">
					<van-image :src="item.image" fit="cover" class="image"/>
				</van-swipe-item>
			</van-swipe>
			<div class="swipe-name">{{bannerList[current].name}}</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header from '@/components/head2022'
	import Footer from '@/components/foot2022'
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				bannerList:[
					{name:'私教室',
					image: require("../../assets/images/new/fitness1.png")},
					{name:'单车房',
					image: require("../../assets/images/new/fitness2.png")},
					{name:'瑜伽室',
					image: require("../../assets/images/new/fitness3.png")},
					{name:'器械区',
					image: require("../../assets/images/new/fitness4.png")},
				],
				current:0,
			}
		},

		methods: {
			change(e){
				this.current=e;
			}
		}
	}
</script>

<style lang="scss">
	#fitness-wrap{
		width: 100%;
		.title{
			font-size: 26px;
			font-weight: bold;
			line-height:26px;
			text-align: center;
			margin-bottom:33px;
			padding-top: 106px;
		}
		.subtitle{
			font-size:16px;
			line-height: 16px;
		}
		.intr{
			padding: 0 27px;
			font-size: 12px;
			line-height: 20px;
			margin-top: 87px;
		}
		.bg1{
			background: url('../../assets/images/new/fitnessbg1.png') no-repeat;
		}
		.bg2{
			background: url('../../assets/images/new/fitnessbg2.png') no-repeat;
		}
		.section {
			background-size: cover;
			box-sizing: border-box;
			height: 100vh;
			color: #FFFFFF;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.btn {
				font-size: 12px;
				line-height: 32px;
				text-align: center;
				cursor: pointer;
				width:247px;
				height:32px;
				border: 1px solid #fff;
				border-radius:16px;
				margin-top:43vh;
			}
		}
		.van-swipe{
			color:#000000 ;
			text-align: center;
			margin-bottom: 70px;
			.intr{
				margin-top: 6px;
				text-align: left;
			}
			.subtitle{
				margin:42px 0 33px;
				font-size:20px;
				font-weight: bold;
			}
			.swipe-name{
				font-size: 18px;
				font-weight: bold;
				margin-top: 20px;
			}
		}
		.banner {
			width: 77.34%;
			height: 192px;
			margin: 0 auto;
			border-radius: 5px;
			.image {
				width: 100%;
			}
			:deep(.van-swipe__indicator) {
				width: 30px;
				height: 2px;
				border-radius: initial;
			}
		}
	}
</style>